import { openPopUp } from "../components/popUp";
import { sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

function getNewsletterCount(onFinish) {

    sendRequest("newsletter/count/normal", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.count);
        }else {
            console.log(r);
        }
    }, getSessionId());
}

function sendNewsletter(title, msg, onFinish) {
    sendRequest("newsletter/send", "POST", {
        type: "normal",
        "subject": title,
        "msg": msg
    }, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp("Ein Fehler ist aufgetreten");
            console.log(r);
        }
    }, getSessionId());
}

export {getNewsletterCount, sendNewsletter}