import React, { useEffect, useState } from "react";

import "../style/newsletter.css";

import Page from "../components/page";
import TextField from "../components/textField";
import Button from "../components/button";
import { getNewsletterCount } from "../content/newsletterManager";
import { openPopUp } from "../components/popUp";
import { openActionPage } from "../components/actionPage";
import ConfirmNewsletterAction from "./action/confirmNewsletter";

export default function DashboardSendNewsletterPage() {

    const [title, setTitle] = useState();
    const [msg, setMsg] = useState();
    const [count, setCount] = useState(0);

    useEffect(() => {
        getNewsletterCount(setCount);
    })

    return <Page id="content">
        <div className="main-box">
            <div className="content">
                <h3>Emails im Newsletter: {count}</h3>
                <TextField label="Titel" placeholder="Titel eingeben" onChange={setTitle}/>
                <TextField label="Nachricht" placeholder="Newsletter Inhalt eingeben" autogrow textarea onChange={setMsg}/>
                <Button onClick={() => {
                    if(!title || !msg) {
                        openPopUp("Titel und Nachricht dürfen nicht leer sein");
                        return;
                    }
                    openActionPage(<ConfirmNewsletterAction title={title} msg={msg}/>);
                }}>Senden</Button>
            </div>
        </div>
    </Page>
}