import React, { useState } from "react";

import Button from "../../components/button";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import { sendNewsletter } from "../../content/newsletterManager";
import { openPopUp } from "../../components/popUp";

export default function ConfirmNewsletterAction(props) {

    const [loading, setLoading] = useState(false);

    return <ActionPage loading={loading}>
        <p>Sicher, dass du deinen Newsletter eintrag so absenden möchtest?</p>
        <Button onClick={() => {
            setLoading(true);
            sendNewsletter(props.title, props.msg, () => {
                openPopUp("Newsletter erfolgreich gesendet");
                closeActionPage();
            });
        }}>Ja, Senden</Button>
    </ActionPage>;
}